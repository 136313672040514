<template>
  <div :class="['landing-page-template']">
    <!-- Header -->
    <GlobalHeader v-if="contentData.header" :content="contentData.header" />

    <div class="grid-container mx-auto px-4 xxl:px-6">
      <section class="error">
        <div class="error-image-wrapper mb-6 lg:mb-10 xl:mb-0">
          <img :src="contentData.image" alt="error" />
        </div>
        <div class="error-content pb-10 xl:pb-0">
          <h1 v-if="contentData.title" class="mb-4">{{ contentData.title }}</h1>
          <HtmlContent
            v-if="contentData.description"
            :content="contentData.description"
            class="mb-6"
          ></HtmlContent>
          <CtaButton
            :variant="contentData.ctaButtonVariant"
            :size="contentData.ctaButtonSize"
            :gtm="contentData.ctaGTM"
            href="/"
            >{{ contentData.ctaButtonText }}</CtaButton
          >
        </div>
      </section>
    </div>
    <!-- Footer -->
    <GlobalFooter v-if="contentData.footer" :content="contentData.footer" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { usePagesStore } from '#root/stores/storePages.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'

import GlobalHeader from '#root/components/global/GlobalHeader.vue'
import GlobalFooter from '#root/components/global/GlobalFooter.vue'
import CtaButton from '#root/components/buttons/CtaButton.vue'
import HtmlContent from '#root/components/utils/HtmlContent.vue'

const contentData = computed(() => {
  const pageStore = usePagesStore()
  const globalStore = useGlobalStore()

  const ctaButton = globalStore.options?.elements?.error_cta.linkedItems[0]
  const ctaButtonText =
    ctaButton.system.type === 'link_model'
      ? ctaButton.elements.link_text.value || ''
      : ctaButton.elements.title.value || ''

  const ctaButtonURL =
    ctaButton.system.type === 'link_model'
      ? ctaButton.elements.site_link.linkedItems[0].elements.url || ''
      : ctaButton.elements.url.value || ''

  const ctaButtonVariant =
    ctaButton.system.type === 'link_model'
      ? ctaButton.elements.variant.value[0].codename || 'primary'
      : 'primary'

  const ctaButtonSize =
    ctaButton.system.type === 'link_model' ? ctaButton.elements.sizes.value[0].codename || 'scale' : 'scale'

  const ctaGTM = '404_home_button_cta'

  return {
    header: pageStore.header.elements,
    footer: pageStore.footer.elements,
    image: globalStore.options.elements.error_image.value[0].url,
    title: globalStore.options.elements.error_title.value || '',
    description: globalStore.options.elements.error_text.value || '',
    ctaButtonText,
    ctaButtonURL,
    ctaButtonVariant,
    ctaButtonSize,
    ctaGTM
  }
})
</script>

<style lang="scss">
.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: $spacing-12 0;

  @include media-query('desktop') {
    flex-direction: row;
  }
}

.error-image-wrapper {
  max-width: 504px;

  img {
    width: 100%;
    height: auto;

    @include media-query('phablet') {
      width: 404px;
    }

    @include media-query('desktop-large') {
      width: 504px;
    }
  }
}

.error-content {
  max-width: 100%;
  margin-left: auto;

  @include media-query('desktop') {
    max-width: 488px;
  }

  @include media-query('desktop-large') {
    max-width: 608px;
  }

  h1 {
    font-size: 4rem;
    line-height: 5.2rem;
    color: $headers;
  }

  .cta-btn {
    min-width: 223px;
  }
}
</style>
